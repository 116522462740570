import React, { Component, createRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import SimpleBar from 'simplebar-react';
import { isEmpty } from 'lodash';
// import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import { ReportGA } from '../../utils/ga';
import '../../styles/scrollbars/simplebar.min.css';
import { store } from '../../init-store';
import Spinner from '../general/spinner';
import Section from '../general/section';
import PageWrapper from '../general/page-wrapper';
import Image from '../general/image';
import colours from '../../styles/colours';
import Welcome from './welcome';
import HowDoesItWork from './how-does-it-work';
import InviteFriends from '../../containers/dashboard/invite-friends';
import ProfileSP from '../../containers/dashboard/profile-sp';
// import VerifiedSP from './verified-sp';
import CallToActionBanner from './cta-banner';
import DbsSP from './dbs-sp';
import IncompleteAvailability from './incomplete-availability';
import LocalAvailability from './local-availability';
import DocsPending from './docs-pending';
import ClashingBookings from './clashing-bookings';
import GetVerified from './get-verified';
import VerifyEmail from './verify-email';
import SearchbarTopContainer from '../../containers/Navigation/searchbar-top-container';
import UpcomingDates from './upcoming-dates';
import history from '../Navigation/history';
import { landing } from '../../text';
import Testimonial from '../general/testimonial';
import { ScrollColDiv } from '../layout/index';
import avatarPlaceholder from '../../images/avatar.min.png';
// import CarouselA from '../general/carousel';
import Footer from '../../containers/general/footer';
// import LogoSitterpoint from '../../images/logo_sitterpoint.min.svg';
import { navBarHeight, infoBarHeight } from '../../styles/layout';
import { getTotalBookings } from '../../lib/bookingsHelpers';
import { DASHBOARD, MESSAGES } from '../../constants/ga-category';
import * as ROUTES from '../../constants/routes';
import * as VERIFIED_STATUS from '../../constants/verified-status';
import landingBackgroundPic from '../../images/landing_dashboard_bg.min.jpg';


// gotIts
const PROFILE_SP = 'profile_sp';
const VERIFIED_SP = 'verified_sp';
const DBS_SOME_SP = 'dbs_some_sp';
const DBS_ALL_SP = 'dbs_all_sp';
const WELCOME = 'welcome';
export const WELCOME_PAGE = 'welcome_page';


class Dashboard extends Component {

  constructor (props) {
    super(props);

    this.searchBannerRef = createRef();
    this.scrollableNodeRef = createRef();
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      isLoaded: false,
      dashboardNavInitialised: false
    };

  }


  componentDidMount () {

    const { location, user_id, home_address, setActiveThreadId, active_thread_id, handleGetUpcomingAvailability } = this.props;
    console.log('dashboard did mount this.props', this.props);
    console.log('dashboard did mount active_thread_id', active_thread_id);
    console.log('dashboard did mount user_id', user_id);
    // persistor.pause();

    if (this.scrollableNodeRef.current === null) {
      // console.log('do not add scroll listener');
    } else {
      // console.log('this.scrollableNodeRef.current', this.scrollableNodeRef.current);
      // console.log('add scroll listener');
        this.scrollableNodeRef.current.addEventListener('scroll', _.throttle(this.handleScroll, 20));
    }


    if (user_id) {

      this.props.handleGetUser(user_id); // not running?
      this.props.handleGetAllThreads(user_id); // for bookings calendar
      // get AllThreads for user
      // calendar is made up of user.availability & threads.booked for user_id

      if (home_address) {
        // this.props.handleGetFlexibleUsers(user_id, home_address);
      }
      console.log('dashboard user_id:', user_id);
      if (active_thread_id && active_thread_id !== undefined) {
        // if thread_id was set on Landing (logged out)
        console.log('dashboard did mount with active thread:', active_thread_id);
        this.handleGetMessageFromLink(active_thread_id);

        setActiveThreadId(undefined);

      } else if (location.search) {
        const searchParams = new URLSearchParams(location.search);
        const threadId = Number(searchParams.get('thread'));
        console.log('dashboard did mount:', threadId);

        if (!active_thread_id || active_thread_id === undefined) {
          if (threadId) {
            // else just getData and Redirect here
            console.log('dashboard did mount with threadId:', threadId);
            this.handleGetMessageFromLink(threadId);
          }
        }
      }


    }

    if (home_address && !isEmpty(home_address)) {
      const { lat, lng } = home_address.latLng;

      handleGetUpcomingAvailability(user_id, lat, lng);
    }


    ReportGA({
      category: DASHBOARD,
      action: "Dashboard page shown to user"
    });


    const { resetEditSearch, clearSearchQuery } = this.props;

    resetEditSearch();

    clearSearchQuery();

    setTimeout(() => {
      // delay loading of dashboard until any redirects have taken place
      this.setState({ isLoaded: true });
    }, 2000);


  }

  componentWillReceiveProps (nextProps) {

    const { user_id, home_address, active_thread_id, numberOfUnreadMessages, dropdown_nav_is_open, openDropdownNav, handleGetUpcomingAvailability } = nextProps;

    console.log('dashboard will rec this.props', this.props);
    console.log('dashboard will rec home_address', home_address);
    console.log('dashboard will rec active_thread_id', active_thread_id);
    console.log('dashboard will rec user_id', user_id);

    if (home_address && !_.isEmpty(home_address)) {
      if (this.props.home_address !== home_address) {
        // if nextProps home_address is different from this.props home_address, then refresh getFlexibleUsers.
        // when address is populated, get Flexible Users in prep for search results
        this.props.handleGetFlexibleUsers(user_id, home_address);
        // home_address has changed, check for availability in the area
        const { lat, lng } = home_address.latLng;
        handleGetUpcomingAvailability(user_id, lat, lng);
      }
    }

    // if first load unread messages in Inbox, open dropdown navbar so user can see inbox button and red indicator


    if (!this.state.dashboardNavInitialised && numberOfUnreadMessages > 0 && !dropdown_nav_is_open) {
      openDropdownNav();
      this.setState({ dashboardNavInitialised: true });
    }

  }


  componentWillUnmount () {

    if (this.scrollableNodeRef.current === null) {

    } else {
      this.scrollableNodeRef.current.removeEventListener('scroll', _.throttle(this.handleScroll, 20));
    }
  }


  handleClickCalendar = () => {

    history.push({
      pathname: ROUTES.AVAILABILITY
    });

    ReportGA({
      category: DASHBOARD,
      action: `User pressed Go to Calendar button on Dashboard page`
    });

  }


  handleClickGotIt = (type) => {

    const { gotItClick, user_id } = this.props;

    console.log('dashboard click handleClickGotIt:', user_id, type);
    gotItClick(user_id, type);

    ReportGA({
      category: DASHBOARD,
      action: `User pressed Got It ${type} button on Dashboard page`
    });
  }


  handleSearch = () => {
    const { showSearchBanner } = this.props;
    // show search banner then
    // this.scrollToTop();
    showSearchBanner();

    ReportGA({
      category: DASHBOARD,
      action: `User pressed Search button from Upcoming Dates on Dashboard page`
    });
  }

  handleShowAddToHomeScreen = () => {

    ReportGA({
      category: DASHBOARD,
      action: `User shown Add To HomeScreen banner on Dashboard page`
    });
  }

  handleInstallAddToHomeScreen = () => {

    ReportGA({
      category: DASHBOARD,
      action: `User clicked Install Add To HomeScreen banner on Dashboard page`
    });
  }

  handleCancelAddToHomeScreen = () => {

    ReportGA({
      category: DASHBOARD,
      action: `User clicked Cancel on Add To HomeScreen banner on Dashboard page`
    });
  }

  handleScroll = (e) => {
    // if this element scrolls out of view, close dropwdown to maintain scroll behaviour
    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // console.log('e.target.scrollHeight', e.target.scrollHeight);
    // console.log('e.target.clientHeight', e.target.clientHeight);
    // if (bottom) {
    //   const { setDateValueOpen } = this.props;
    //   setDateValueOpen(false);
    // }
  }

  handleScroll () {
    // const { scrollPos } = this.state;
    const { setShowNavbar } = this.props;
    // console.log('handleScroll fired');
    // console.log('this.scrollableNodeRef.current.scrollTop:', this.scrollableNodeRef.current.scrollTop);
    // console.log('scrollPos:', scrollPos);
    // console.log('show?:', this.scrollableNodeRef.current.scrollTop < scrollPos);

    // if (!this.state.scrolledToBottomOnce) {
    //   this.setState({
    //     scrollPos: 0,
    //     show: true
    //   });
    // }
    // if (this.state.scrolledToBottomOnce) {
      // this.setState({
      //   scrollPos: this.scrollableNodeRef.current.scrollTop,
      //   show: this.scrollableNodeRef.current.scrollTop < scrollPos
      // });
    console.log('this.tabbarRef:', !this.tabbarRef.current ? null : this.tabbarRef.current);
    const tabbarTop = !this.tabbarRef.current ? null : this.tabbarRef.current.offsetTop;
    console.log('sticky this.scrollableNodeRef.current.scrollTop:', this.scrollableNodeRef.current.scrollTop);
    console.log('sticky tabbarTop:', tabbarTop);
    const initialTabbarTop = this.state.initialTabbarTop;
    const sticky = this.scrollableNodeRef.current.scrollTop >= initialTabbarTop; // tabbarTop;
    // console.log('sticky this.tabbarRef.current:', this.tabbarRef.current);
    // console.log('sticky scrollPos:', scrollPos);

    // console.log('sticky this.scrollableNodeRef.current:', this.scrollableNodeRef.current);


    this.setState({
      scrollPos: this.scrollableNodeRef.current.scrollTop,
      sticky
    });

      // if (this.scrollableNodeRef.current.scrollTop < scrollPos) {
      //   setShowNavbar(true);
      // } else {
        // setShowNavbar(false);
      // }

    // }
  }


  handleGetMessageFromLink (message_thread_id) {

    const { getMessageThread } = this.props;
    // could add tags to the email link to know which email it came from

    ReportGA({
      category: MESSAGES,
      action: `User clicked on Thread link from Email message`
    });

    console.log('getMessageThread routing to message');
    console.log('getMessageThread message_thread_id', message_thread_id);

    getMessageThread(message_thread_id);

      // push thread route


  }


  render () {
    const { location, window_width, window_height, home_address, email_verified, flexible, allThreads, user_id, profile_progress, isSearching, isWelcome, got_it, verified_status, resendVerifyEmail, adults, combinedBookingsArray, sit_approved_req_count, sit_approved_sit_count, upcomingAvailability } = this.props;

    const narrowScreenOrientation = window_width > 600 ? 'row' : 'column';

    console.log('dashboard render this.props', this.props);
    console.log('dashboard render this.state.isLoaded :', this.state.isLoaded);

    if (isSearching || !this.state.isLoaded) {
      return (
        <PageWrapper window_height={window_height - (navBarHeight + infoBarHeight)}>
          <div style={{ display: 'flex', backgroundColor: colours.verylightgray, height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>
        </PageWrapper>
      );
    }

    const showInCompleteProfile = !isWelcome && (profile_progress !== 100);
    const showWelcome = (isWelcome || showInCompleteProfile) && (!got_it.includes(WELCOME));
    const showHowDoesItWork = true;
    // console.log('INCLUDES got_it:', got_it);
    const profileIncomplete = profile_progress !== 100;
    const showProfileSP = (profile_progress === 100 && sit_approved_req_count === 0 && sit_approved_sit_count === 0 && verified_status !== VERIFIED_STATUS.NOT_VERIFIED);
    // const showProfileSP = ((profile_progress === 100) && (!got_it.includes(PROFILE_SP)));
    // const showVerifiedSP = (verified_status !== 'not_verified') && (!got_it.includes(VERIFIED_SP));
    const showDbsSomeSP = (verified_status === VERIFIED_STATUS.DBS_SOME) && (!got_it.includes(DBS_SOME_SP));
    const showDbsAllSP = (verified_status === VERIFIED_STATUS.DBS_ALL) && (!got_it.includes(DBS_ALL_SP));

    const uploadedDocsUnverifiedAdults = adults && adults.map((adult) => {

      const uploadedDocsArray = adult.uploaded_docs && adult.uploaded_docs
      .filter((doc) => {
        return doc.doc_name !== 'id_selfie';
      })
      .map((doc) => {
        return doc.doc_id;
      });
      // console.log('DASH uploadedDocsArray', uploadedDocsArray);

      const verifiedDocsArray = !adult.verified_docs ? [] : adult.verified_docs.map((doc) => {
        return doc.doc_id;
      });
      // console.log('DASH verifiedDocsArray', verifiedDocsArray);

      const uploadedDocsUnVerifiedArray = uploadedDocsArray && uploadedDocsArray.filter((doc_id) => {
        // if doc_id not in verified docs, then must be unverified

        return !verifiedDocsArray.includes(doc_id);
      });
      // console.log('DASH uploadedDocsUnVerifiedArray', uploadedDocsUnVerifiedArray);
      // console.log('DASH uploadedDocsUnVerifiedArray.length', uploadedDocsUnVerifiedArray && uploadedDocsUnVerifiedArray.length);

      if (!uploadedDocsUnVerifiedArray || (uploadedDocsUnVerifiedArray && uploadedDocsUnVerifiedArray.length === 0)) {
        return false;
      }

      return true;
    });
    // console.log('uploadedDocsUnverifiedAdults:', uploadedDocsUnverifiedAdults); // returns boolean, true = there are adults who've uploaded docs that are unverified
    // console.log('DASH adults', adults);

    // need to calculate if at least one adult unverified and has pending docs
    const allDocsUploadedForMainAdult =
    adults && adults.length !== 0
    && adults[0].uploaded_docs
    && adults[0].uploaded_docs.some(doc => doc.doc_name === 'id')
    && adults[0].uploaded_docs.some(doc => doc.doc_name === 'poa');

    const allDocsVerifiedForMainAdult =
    adults && adults.length !== 0
    && adults[0].uploaded_docs
    && adults[0].uploaded_docs.filter((doc) => {
      return doc.doc_name !== 'id_selfie';
    })
    .reduce((acc, doc) => {
      // test if any docs are still unverified
      // console.log('Profile acc:', acc);
      const isVerified = adults[0].verified_docs && adults[0].verified_docs.some(e => e.doc_id === doc.doc_id);
      return !acc ? false : isVerified; // if acc false, already a doc unverified so set to false, else set to current doc.verified
    }, true);


    const uploadedDocsUnverified = uploadedDocsUnverifiedAdults && uploadedDocsUnverifiedAdults.includes(true);
    // if any adults have unverified docs uploaded then return true;

    const profileCompleteExceptForPendingDocs = profile_progress >= 80 && allDocsUploadedForMainAdult && !allDocsVerifiedForMainAdult;
    const showDocsPending = uploadedDocsUnverified && profileCompleteExceptForPendingDocs; 
    // only show if ALL docs have been uploaded and at least one is unverified
    // relogiced as per https://github.com/rickardinho/sitter/issues/513

    const showGetVerified = false; // ((verified_status === 'not_verified') && !uploadedDocsUnverified);
    const showVerifyEmail = !email_verified;

    
    // LOGIC TO show/hide Complete Availability
    // calc if more than X hours in next Y days
    const HOURS_REQUIRED = 6;
    const DAYS_ANALYSED = 14;

    // sum available_hours on available_dates array in next Y days
    const userTotalAvailableHoursInNextYDays = combinedBookingsArray && combinedBookingsArray.reduce((acc, item) => {
      // console.log('acc:', acc);
      // console.log('dashboard booking item:', item);
      if (item.booking_type !== 'available') {
        return acc;
      } else {
        const startTime = moment(JSON.parse(item.available_dates)[0], 'YYYY-MM-DD HH:mm:ss');
        const nowMoment = moment();
        if (startTime.isBefore(nowMoment)) {
          return acc;
        }
        // console.log('dashboard startTime:', startTime);
        // console.log('dashboard nowMoment:', nowMoment);
        const daysFromNow = startTime.diff(nowMoment, 'days');
        console.log('dashboard days from now:', daysFromNow);
        const hoursToAdd = (daysFromNow >= DAYS_ANALYSED) ? 0 : item.available_hours;
        return hoursToAdd + acc;
      }
    }, 0);
    
    console.log('dashboard userTotalAvailableHoursInNextYDays:', userTotalAvailableHoursInNextYDays);
    const userHasEnoughAvailability = userTotalAvailableHoursInNextYDays >= HOURS_REQUIRED;
    
    const showCompleteAvailability = !flexible && !userHasEnoughAvailability; // if user is NOT flexble and NOT entered enough availability show CTA
    // END OF LOGIC TO show/hide Complete Availability


    const showUpcomingDates = false; // to revise
    const showInviteFriends = true;

    console.log('upcomingAvailability:', upcomingAvailability);
    const totalAvailableHoursInNext4Weeks = upcomingAvailability && upcomingAvailability.length > 0 && upcomingAvailability.reduce((acc, item) => {
      // console.log('acc:', acc);
      return item.available_hours + acc;
    }, 0);

    // console.log('totalAvailableHoursInNext4Weeks:', totalAvailableHoursInNext4Weeks);
    const isEnoughLocalAvailability = totalAvailableHoursInNext4Weeks >= 4; // more than 4 hours then show
    const showLocalAvailability = home_address && !_.isEmpty(home_address) && (!upcomingAvailability || isEnoughLocalAvailability); //  if not loaded/fetching, then show, but will show spinner
    console.log('DASH showLocalAvailability:', showLocalAvailability);

    // console.log('combinedBookingsArray:', combinedBookingsArray);
    const totalBookings = getTotalBookings(combinedBookingsArray);
    const showClashingBookings = totalBookings.clashes.length > 0;

    return (


      <PageWrapper window_height={window_height - (navBarHeight + infoBarHeight)}>

        { // disable for now as not working as planned
          // <AddToHomeScreen
          //   debug // remove in production
          //   onShow={() => this.handleShowAddToHomeScreen()}
          //   onInstall={() => this.handleInstallAddToHomeScreen()}
          //   onCancel={() => this.handleCancelAddToHomeScreen()}
          //   appId='Sitterpoint'
          //   title='Install SitterPoint?'
          //   src={LogoSitterpoint}
          //   cancelMsg='Not Now'
          //   installMsg='Install'
          //   guidanceCancelMsg='Close'
          // />
        }
        <div style={{
          display: 'flex', overflow: 'hidden', width: '100%', height: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', borderWidth: 0,
          borderColor: 'red',
          borderStyle: 'solid'
        }}>

          <div style={{ borderWidth: 0, borderColor: 'pink', borderStyle: 'solid', flexDirection: 'column', display: 'flex', height: '100%', flex: 1 }}>
            <div style={{ height: '100%', borderWidth: 0, borderColor: 'blue', borderStyle: 'solid' }}>
              <SimpleBar
                forceVisible='n'
                // autoHide={false}
                scrollableNodeProps={{ ref: this.scrollableNodeRef }}
                onScroll={this.handleScroll}

                style={{
                  // maxHeight: '90%',
                  height: window_height -
                  ((infoBarHeight + navBarHeight) : 0),
                  borderColor: 'blue', borderWidth: 0, borderStyle: 'solid'
              }}>

                <ScrollColDiv
                  padding={false}
                  style={{
                    background: colours.verylightgray,
                    borderColor: 'blue', borderWidth: 0, borderStyle: 'solid'
                  }}
                >
                  <div
                    style={{ position: 'absolute', top: -30 }}
                    ref={this.searchBannerRef} />
                  <SearchbarTopContainer searchBannerRef={this.searchBannerRef} />

                  {
                    // main image
                  }
                  <div style={{ // background image
                    width: '100%',
                    // height: window_width > 750 ? 750 : (window_width / 1), // carpet part of image starts at 56% of height (square)
                    borderColor: 'blue', borderWidth: 0, borderStyle: 'solid', overflow: 'hidden' }}>
                    <Image
                      alt='background'
                      style={{ width: '100%', objectFit: 'cover' }}
                      src={ landingBackgroundPic }
                    />
                  </div>

                  <Section
                    // zIndex={2}
                    noTopPadding
                    fullWidth
                    noSidePadding
                    paddingTop={0}
                    noBottomPadding backgroundColor={colours.verylightgray}>


                    {
                      <CallToActionBanner user_id={user_id} allThreads={allThreads} profileIncomplete={profileIncomplete} showVerifyEmail={showVerifyEmail} resendVerifyEmail={resendVerifyEmail} showCompleteAvailability={showCompleteAvailability} profileCompleteExceptForPendingDocs={profileCompleteExceptForPendingDocs} />
                    }

                  </Section>

                  <Section
                    // zIndex={2}
                    noTopPadding
                    fullWidth
                    noSidePadding
                    paddingTop={0}
                    noBottomPadding backgroundColor={colours.verylightgray}>

                    { (showProfileSP)
                      && <ProfileSP profileProgress={profile_progress} verified_status={verified_status} handleClickGotIt={() => this.handleClickGotIt(PROFILE_SP)} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }
                  </Section>

                  <Section
                    // zIndex={2}
                    noTopPadding
                    paddingTop={4}
                    noBottomPadding backgroundColor={colours.verylightgray}>


                    {
                      // (showVerifyEmail)
                      // removed as included in CTA banner
                      // && <VerifyEmail resendVerifyEmail={resendVerifyEmail} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} /> // change text
                    }

                    { (showDocsPending)
                      && <DocsPending window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    {
                      (showClashingBookings)
                      && <ClashingBookings window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    { (showDbsSomeSP)
                      && <DbsSP profileProgress={profile_progress} handleClickGotIt={() => this.handleClickGotIt(DBS_SOME_SP)} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    { (showDbsAllSP)
                      && <DbsSP profileProgress={profile_progress} handleClickGotIt={() => this.handleClickGotIt(DBS_ALL_SP)} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    {
                      // (showVerifiedSP)
                      // && <VerifiedSP profileProgress={profile_progress} handleClickGotIt={() => this.handleClickGotIt(VERIFIED_SP)} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    { (showWelcome)
                      && <Welcome profileProgress={profile_progress} onlyPendingDocs={profileCompleteExceptForPendingDocs} handleClickGotIt={() => this.handleClickGotIt(WELCOME)} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    { (showInviteFriends)
                      && <InviteFriends location={location} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }


                    { (showHowDoesItWork)
                      && <HowDoesItWork
                      // profileProgress={profile_progress}
                      // handleClickGotIt={() => this.handleClickGotIt(WELCOME)}
                        window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }


                    { (showGetVerified)
                      && <GetVerified profileProgress={profile_progress} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                    }

                    {
                      (showLocalAvailability)
                      && <LocalAvailability upcomingAvailability={upcomingAvailability} />
                    }

                    {
                      // row / column depending on screenWidth
                    }

                    <div
                      style={{ display: 'flex', width: '100%',
                        flexDirection: window_width > 700 ? 'column' : 'column' // change to 'row' : 'column' to show side by side
                    }}>
                      {
                        // (showCompleteAvailabilityPromo)
                        // && <IncompleteAvailability handleClick={this.handleClickCalendar} narrowScreenOrientation={narrowScreenOrientation} />
                      }

                      { (showUpcomingDates)
                        && <UpcomingDates handleSearch={this.handleSearch} user_id={user_id} allThreads={allThreads} window_width={window_width} narrowScreenOrientation={narrowScreenOrientation} />
                      }

                    </div>


                  </Section>

                  {
                    // <div style={{ background: colours.white, width: '100%', flexDirection: 'column', paddingTop: 20, paddingBottom: 0, borderColor: 'green', borderWidth: 0, borderStyle: 'solid', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    //
                    //   <SectionHeader title={landing.testimonials.title} subtitle={landing.testimonials.subtitle} highlightedSubtitle={landing.testimonials.highlightedSubtitle} />
                    //
                    //   <CarouselA
                    //   // content={testimonialContent}
                    //     window_width={window_width}
                    //   >
                    //     {testimonialContent}
                    //   </CarouselA>
                    //
                    // </div>
                  }

                  <Footer small />


                </ScrollColDiv>
              </SimpleBar>
            </div>

          </div>


        </div>


      </PageWrapper>


    );
  }
}


export default Dashboard;
